<template>
    <div class="main-wrapper">
        <div class="main-tab">
            <el-breadcrumb separator=">" class="customBreadcrumb">
                <el-breadcrumb-item>考试数据</el-breadcrumb-item>
                <el-breadcrumb-item>查看学生数据</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="right">
                <el-button type="primary" plain @click="exportBtn">导出成绩</el-button>
                <el-button type="primary" @click="goBack">返回</el-button>
            </div>
        </div>
        <el-table :data="studentList" class="customTable" style="width: 100%; flex: 1; margin-top: 20px" height="1%"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="student_name" label="学生名称"></el-table-column>
          <el-table-column prop="essay_score" :label="`理论题得分（${theory_rate}%）`" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.essay_score}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="operation_score" :label="`操作题得分（${practic_rate}%）`" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.operation_score}}</span>
            </template>
          </el-table-column>
          <el-table-column :label="`合计得分（${total_rate}%）`" align="center">
            <template slot-scope="scope">
              <span>{{scope.row.essay_score + scope.row.operation_score}}</span>
            </template>
          </el-table-column>
            <el-table-column label="操作" align="center" width="200px">
                <template slot-scope="scope">
                    <el-link type="primary" :underline="false">
                        <router-link class="a-link" :to="{path: '/examCenter/examData/theory', query: {id: scope.row.student_id, examId: examId,isGrade:true, method: 'teaExam',level: level }}">查看详情</router-link>
                    </el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pages-center" style="margin: 20px 0"
                       :current-page="listPages.currentPageNum"
                       :page-size="listPages.eachPageNum"
                       layout="prev, pager, next, jumper"
                       :total="listPages.total"
                       @current-change="pageCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {examExamStuList, examExportStuScore, examGetExamRate} from '@/utils/apis'
    import axios from "axios";
    export default {
        data() {
            return {
                examId: this.$route.query.examId || null,
                studentList: [],
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                teacherToken:localStorage.getItem('teacherToken') || '',
                theory_rate:0,
                practic_rate:0,
                total_rate:0,
                level:this.$route.query.level || null
            }
        },
        mounted() {
            this.getExamGetExamRate();
            this.getList();
        },
        methods: {
            // 获取分数比例
            getExamGetExamRate(){
              let params = {
                id:this.examId
              }
              examGetExamRate(params).then((res)=>{
                this.practic_rate = res.data.practic_rate;
                this.theory_rate = res.data.theory_rate;
                this.total_rate = res.data.total_rate;
              }).catch((err)=>{
                console.log('err',err)
              })
            },
            // 获取列表
            getList() {
                let params = {
                  exam_id:this.examId,
                  paging:1,
                  page:this.listPages.currentPageNum,
                  pageSize:this.listPages.eachPageNum
                }
                examExamStuList(params).then((res)=>{
                  this.studentList = res.data.data;
                  this.listPages.total = res.data.total;
                }).catch((err)=>{
                  console.log('err',err)
                })
            },
            // 切换页面
            pageCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getList()
            },
            exportBtn() {
                this.$confirm('是否导出该成绩', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                }).then(() => {
                  let params = {
                    exam_id:this.examId
                  }
                  let _this = this;
                  axios({
                    method: 'get',
                    params:params,
                    url:'exam/exportStuScore',
                    responseType:'blob',
                    headers:{'Authorization': _this.teacherToken}
                  }).then((res)=>{
                    let blob = new Blob([res.data], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'}); //application/vnd.openxmlformats-officedocument.spreadsheetml.sheet这里表示xlsx类型
                    let downloadElement = document.createElement('a');
                    let href = window.URL.createObjectURL(blob); //创建下载的链接
                    downloadElement.href = href;
                    downloadElement.download = '导出成绩.xlsx'; //下载后文件名
                    document.body.appendChild(downloadElement);
                    downloadElement.click(); //点击下载
                    document.body.removeChild(downloadElement); //下载完成移除元素
                    window.URL.revokeObjectURL(href); //释放掉blob对象
                  }).catch((err)=>{
                    console.log('err',err)
                  })
                }).catch(() => {
                    this.$message.info('已取消导出');
                })
            },
            goBack() {
                this.$router.push('/examCenter/examData')
            }
        }
    }
</script>

<style scoped lang="scss">
    .main-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
    }
    .main-tab {
        font-size: 16px;
        line-height: 1;
        border-bottom: 1px solid #F1F5FF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 39px;
        position: relative;
        .right {
            position: absolute;
            top: -10px;
            right: 0;
        }
    }
    .a-link {
        color: inherit;
    }
</style>